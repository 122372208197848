import React from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import DetailPageHeader from '@components/DetailPageHeader';
import DetailCard from '@components/core/DetailCard';
import { computeUrls } from '@config/routes';
import { modelsMeta } from '@config/meta/compute';
import useToast from '@hooks/useToast';
import { selectProfile } from '@redux/profileSlice';
import { useParams } from 'react-router-dom';
import { createRecord } from '@config/functions/requests';
import DetailAccordionView from '@components/DetailAccordionView';
import { handleCreateFormErrors } from '@config/functions/helperFunctions';
import { fieldRowsMap, formFlowsRowsMap } from '@config/handleRows/compute';
import { FieldsForm, FormFlowsForm } from '@config/forms/compute';
import { microservices } from '@config/constants';

const { modelsUrls, modelFieldsUrls, formFlowsUrls } = computeUrls;

export default function ModelsDetail({ editMode, labels }) {
  const [notify] = useToast();
  const { id: recordId } = useParams();
  const [recordData, setRecordData] = React.useState(null);
  const user = useSelector(selectProfile);

  // Use the useCallback hook to optimize the getSchema function
  const getSchema = React.useCallback(
    (data) =>
      modelsMeta({
        data,
        modelId: recordId,
      }),
    [recordId]
  );

  const fieldColOptions = React.useMemo(
    () => ({
      modelId: recordId,
      modelName: recordData?.name,
      microserviceId: recordData?.microserviceId,
      microserviceName: recordData?.microservice?.name,
    }),
    [recordId, recordData]
  );

  return (
    <Box>
      {recordData ? (
        <Box>
          <DetailPageHeader
            items={[
              {
                to: `/microservice/${recordData.microserviceId}`,
                text: 'Model',
              },
              { text: recordData?.name },
            ]}
          />
        </Box>
      ) : null}

      <DetailCard
        nodeAPI
        urls={modelsUrls}
        recordId={recordId}
        getSchema={getSchema}
        setData={setRecordData}
      />

      <Box sx={{ mt: 5 }}>
        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Form Flows'
          prefix='ktx'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.formDefn}
          importExportFields={`?modelId=${recordId}`}
          columnKey='formFlowsCompute'
          urls={formFlowsUrls}
          addNewForm={{
            getFields: () => FormFlowsForm(),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                await createRecord({
                  values: {
                    ...values,
                    modelId: recordId,
                  },
                  url: formFlowsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });
                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={formFlowsRowsMap}
          urlParams={`&modelId=${recordId}`}
        />

        <DetailAccordionView
          nodeAPI
          editMode={editMode}
          labels={labels}
          label='Field'
          prefix='0u2'
          microservice={microservices.Compute.name}
          model={microservices.Compute.models.fieldDefn}
          importExportFields={`?modelId=${recordId}`}
          columnKey='fieldsCompute'
          columnOptions={fieldColOptions}
          urls={modelFieldsUrls}
          addNewForm={{
            getFields: ({ values }) =>
              FieldsForm({
                values,
                microserviceId: recordData?.microserviceId,
              }),
            handleSubmit: async (
              values,
              setSubmitting,
              resetForm,
              setAddNewForm,
              refetch,
              setError,
              setFieldError
            ) => {
              try {
                const {
                  enumDefnId,
                  foreignKeyModelId,
                  foreignKeyMicroserviceId,
                  minLength,
                  maxLength,
                  ...rest
                } = values;

                if (rest.dataType === 'Enum' && !enumDefnId?.id) {
                  setFieldError('enumDefnId', 'Please select an option.');
                  return;
                }

                const payload = {
                  ...rest,
                  modelId: recordId,
                };

                if (minLength) {
                  payload.minLength = minLength;
                }

                if (maxLength) {
                  payload.maxLength = maxLength;
                }

                if (foreignKeyMicroserviceId) {
                  payload.foreignKeyMicroserviceId =
                    foreignKeyMicroserviceId?.id;
                }

                if (foreignKeyModelId) {
                  payload.foreignKeyModelId = foreignKeyModelId?.id;
                }

                if (enumDefnId?.id) {
                  payload.enumDefnId = enumDefnId?.id;
                }

                await createRecord({
                  values: payload,
                  url: modelFieldsUrls.list(),
                  token: user.token,
                  actAs: user?.actAs,
                });

                resetForm();
                setAddNewForm(false);
                refetch();
              } catch (err) {
                console.log(err);
                handleCreateFormErrors({ err, setError, notify, values });
              } finally {
                setSubmitting(false);
              }
            },
          }}
          handleRowMap={fieldRowsMap}
          urlParams={`&ordering=order&modelId=${recordId}`}
        />
      </Box>
    </Box>
  );
}
